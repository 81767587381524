import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page403 = () => import('@/views/pages/Page403')
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const UserList = () => import('@/views/admins/UserList')
const User = () => import('@/views/admins/User')

const VehicleList = () => import('@/views/agents/VehicleList')
const Vehicle = () => import('@/views/agents/Vehicle')

const Profile = () => import('@/views/general/Profile')

//
const StockList = () => import('@/views/admins/StockList')
const Stock = () => import('@/views/admins/Stock')

const DisburstmentList = () => import('@/views/admins/DisburstmentList')
const Disburstment = () => import('@/views/admins/Disburstment')

const Company = () => import('@/views/admins/Company')
const CompanyEdit = () => import('@/views/admins/CompanyEdit')

const RoleList = () => import('@/views/admins/RoleList')
const Role = () => import('@/views/admins/Role')


const BrandList = () => import('@/views/admins/BrandList')
const Brand = () => import('@/views/admins/Brand')

const BankList = () => import('@/views/admins/BankList')
const Bank = () => import('@/views/admins/Bank')

const SubCompanyList = () => import('@/views/admins/SubCompanyList')
const SubCompany = () => import('@/views/admins/SubCompany')


const ForwarderList = () => import('@/views/admins/ForwarderList')
const Forwarder = () => import('@/views/admins/Forwarder')

const SupplierList = () => import('@/views/admins/SupplierList')
const Supplier = () => import('@/views/admins/Supplier')


const CustomerList = () => import('@/views/admins/CustomerList')
const Customer = () => import('@/views/admins/Customer')

const SalesReportList = () => import('@/views/admins/SalesReportList')
const SalesReport = () => import('@/views/admins/SalesReport')


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})


router.beforeEach((to, from, next) => {
  // var isAuth = auth.check();
  // if (!isAuth) {
  //   if (to.name == 'Login') {
  //     next();
  //   }
  //   else {
  //     next({ name: 'Login' });
  //   }
  // }
  // else {
  //   next();
  // }

  // Check if the user is authenticated
  const isAuth = auth.check();
  if (!isAuth) {
    if (to.name === 'Login') {
      next(); // Allow login page
    } else {
      next({ name: 'Login' }); // Redirect to login if not authenticated
    }
    return; // Exit early
  }
  // User is authenticated
  const permissions = auth.getPermissions(); // Get user permissions from auth or Vuex
  const moduleName = to.meta?.moduleName;
  console.log("to", to);
  console.log("permissions", permissions);
  console.log("moduleName", moduleName);

  if (moduleName && !permissions[moduleName]?.CanView) {

    console.log("router.options.routes", router.options.routes);

    const firstAllowedRoute = findFirstAllowedRoute(router.options.routes, permissions);
    console.log("firstAllowedRoute", firstAllowedRoute);

    if (firstAllowedRoute) {
      next({ path: firstAllowedRoute.path });
    } else {
      next({ name: '403' });
    }
    return;
  }
  


 next();

})

function findFirstAllowedRoute(routes, permissions) {
  for (const route of routes) {
    const moduleName = route.meta?.moduleName;

       // Skip routes with dynamic parameters (e.g., `:id`)
       if (route.path.includes(':')) {
        continue;
    }
    
    if (moduleName && permissions[moduleName]?.CanView) {
      return route;
    }

    if (route.children) {
      const allowedChild = findFirstAllowedRoute(route.children, permissions);
      if (allowedChild) {
        return allowedChild;
      }
    }
  }
  return null;
}

export default router

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            moduleName: 'Dashboard'
          }
        },
        {
          path: 'agents',
          redirect: '/agents/vehicleList',
          name: 'Agents',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'Vehicle/:id',
              name: 'VehicleById',
              component: Vehicle,
             
            },
            {
              path: 'Vehicle',
              name: 'Vehicle',
              component: Vehicle,
             
            },
            {
              path: '/agents/VehicleList',
              name: 'VehicleList',
              component: VehicleList,
              meta: {
                moduleName: 'Vehicle'
              }
            },
          ]
        },
        {
          path: 'admins',
          redirect: '/admins/userList',
          name: 'User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'User/:id',
              name: 'UserById',
              component: User
            },
            {
              path: 'User',
              name: 'User',
              component: User
            },

            {
              path: 'UserList',
              name: 'UserList',
              component: UserList
            },

            {
              path: 'role/:id',
              name: 'roleById',
              component: Role
            },
            {
              path: 'role',
              name: 'role',
              component: Role
            },
            {
              path: 'roleList',
              name: 'roleList',
              component: RoleList
            },
            {
              path: 'stock/:id',
              name: 'stockById',
              component: Stock
            },
            {
              path: 'stock',
              name: 'stock',
              component: Stock
            },
            {
              path: 'stockList',
              name: 'stockList',
              component: StockList,
              meta: {
                moduleName: 'Stocks'
              }
            },

            {
              path: 'disburstment/:id',
              name: 'disburstmentById',
              component: Disburstment
            },
            {
              path: 'disburstmentList',
              name: 'disburstmentList',
              component: DisburstmentList
            },
            {
              path: 'companyEdit',
              name: 'CompanyEdit',
              component: CompanyEdit
            },
            {
              path: 'company',
              name: 'company',
              component: Company
            },

            {
              path: 'Brand/:id',
              name: 'BrandById',
              component: Brand
            },
            {
              path: 'Brand',
              name: 'Brand',
              component: Brand
            },
            {
              path: 'BrandList',
              name: 'BrandList',
              component: BrandList
            },

            {
              path: 'Bank/:id',
              name: 'BankById',
              component: Bank
            },
            {
              path: 'Bank',
              name: 'Bank',
              component: Bank
            },
            {
              path: 'BankList',
              name: 'BankList',
              component: BankList
            },

            //
            {
              path: 'SubCompany/:id',
              name: 'SubCompanyById',
              component: SubCompany
            },
            {
              path: 'SubCompany',
              name: 'SubCompany',
              component: SubCompany
            },
            {
              path: 'SubCompanyList',
              name: 'SubCompanyList',
              component: SubCompanyList
            },

            //
            {
              path: 'Forwarder/:id',
              name: 'ForwarderById',
              component: Forwarder
            },
            {
              path: 'Forwarder',
              name: 'Forwarder',
              component: Forwarder
            },
            {
              path: 'ForwarderList',
              name: 'ForwarderList',
              component: ForwarderList
            },


            {
              path: 'Supplier/:id',
              name: 'SupplierById',
              component: Supplier
            },
            {
              path: 'Supplier',
              name: 'Supplier',
              component: Supplier
            },
            {
              path: 'SupplierList',
              name: 'SupplierList',
              component: SupplierList
            },

            {
              path: 'Customer/:id',
              name: 'CustomerById',
              component: Customer
            },
            {
              path: 'Customer',
              name: 'Customer',
              component: Customer
            },
            {
              path: 'CustomerList',
              name: 'CustomerList',
              component: CustomerList
            },

            {
              path: 'salesReport/:id',
              name: 'salesReportById',
              component: SalesReport
            },
            {
              path: 'salesReportList',
              name: 'salesReportList',
              component: SalesReportList
            },


          ]
        },
        {
          path: 'general',
          redirect: '/general/profile',
          name: 'Profile',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'profile',
              name: 'profile',
              component: Profile
            },


          ]
        },
      ]
    },
    {
      path: '/admins',
      redirect: '/admins',
      name: 'Admin',
      component: TheContainer,
      
    },


    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '403',
          name: 'Page403',
          component: Page403
        },
           {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}
