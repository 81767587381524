// utils/filterMenu.js
export function filterMenuItems(menuItems, permissions) {
    return menuItems.map(item => {
        if (item._children) {
            item._children = filterMenuItems(item._children, permissions)
        }

        // Fetch required roles dynamically
        console.log('moduleName', item.moduleName);
        if (item.moduleName == undefined)
            return item;
        
        console.log('permissions', permissions);
        for (let index = 0; index < permissions.length; index++) {
            const element = permissions[index];
            if (element.module == item.moduleName) {
                if (element.canView) {
                    return item
                }

            }
        }
        return null;
    }).filter(item => item !== null)
}

export function filterMenuByName(menuConfig, roleModulePermissions, roleSubModulePermissions) {
    const allowedModules = roleModulePermissions
      .filter(permission => permission.canView)
      .map(permission => permission.module.name);

    console.log('allowedModules',allowedModules);
  
    const allowedSubModules = roleSubModulePermissions
      .filter(permission => permission.canView)
      .map(permission => permission.subModule.name);

      console.log('allowedSubModules',allowedSubModules);
  
    return menuConfig.map(menu => {
      if (menu.moduleName && allowedModules.includes(menu.moduleName)) {
        // If it's a top-level module and is allowed
        if (menu._children) {
          menu._children = menu._children.filter(subMenu => {
            return (
              !subMenu.subModuleName || allowedSubModules.includes(subMenu.subModuleName)
            );
          });
        }
        return menu;
      } else if (menu._children) {
        // If it's a dropdown menu, filter based on subModules
        menu._children = menu._children.filter(subMenu => {
          return (
            (subMenu.moduleName && allowedModules.includes(subMenu.moduleName)) ||
            (subMenu.subModuleName && allowedSubModules.includes(subMenu.subModuleName))
          );
        });
        return menu;
      }
    }).filter(menu => menu && (!menu._children || menu._children.length > 0));
  }
  

  